<template>
	<Grid rowGap="medium">
		<GridColumn>
			<FieldSelect
				name="dataType"
				:options="dataTypesOptions"
				:value="formData.dataType"
				@input="handleDataTypeChange"
				placeholder="Select"
				:disabled="readOnly"
			>
				<Icon name="grid" />

				Code Type <a href="#" target="_blank">Type Definitions</a>
			</FieldSelect>
		</GridColumn>

		<GridColumn>
			<FieldSelect
				:options="subTypeOptions"
				name="subType"
				v-model.trim="formData.subType"
				placeholder="Select"
				:disabled="readOnly"
			>
				<Icon name="grid" />

				Code Subtype
			</FieldSelect>
		</GridColumn>

		<GridColumn>
			<Checkboxes>
				<FieldCheckbox
					name="reuse"
					v-model="formData.reuse"
					isDropdown
					:disabled="readOnly"
				>
					This {{ kind }} is re-used from another public or private source
				</FieldCheckbox>

				<FieldCheckbox
					name="optional"
					v-model="formData.optional"
					isDropdown
					:disabled="readOnly"
				>
					Compliance policies for this {{ kind }} are not currently enforced
				</FieldCheckbox>
				
				<FieldCheckbox
					name="hasReadMe"
					v-model="formData.hasReadMe"
					isDropdown
					:disabled="readOnly"
					:allowNA="true"
				>
					This {{ kind }} has a "Read Me"
				</FieldCheckbox>
			</Checkboxes>
		</GridColumn>

		<!-- Case code -->
		<template v-if="kind === 'code'">
			<GridColumn>
				<Field
					name="URL"
					type="text"
					v-model.trim="formData.URL"
					placeholder="https://"
					:disabled="readOnly"
				>
					<Icon name="chain" color="currentColor" />

					Stable URL
				</Field>
			</GridColumn>

			<GridColumn>
				<Field
					name="DOI"
					type="text"
					v-model.trim="formData.DOI"
					placeholder="Enter DOI or PID if available"
					:disabled="readOnly"
				>
					<Icon name="chain" color="currentColor" />

					Digital object identifier (DOI) or PID
				</Field>
			</GridColumn>
		</template>

		<!-- Case software -->
		<template v-if="kind === 'software'">
			<GridColumn>
				<Field
					:error="$v.type.$error"
					v-model.trim="formData.version"
					name="version"
					placeholder="Version"
					:disabled="readOnly"
				>
					<Icon name="windows" color="currentColor" />
					
					Version
				</Field>
			</GridColumn>

			<GridColumn>
				<Field
					name="URL"
					type="text"
					v-model.trim="formData.URL"
					placeholder="https://"
					:disabled="readOnly"
				>
					<Icon name="chain" color="currentColor" />

					Stable URL, DOI, or other link to this object
				</Field>
			</GridColumn>

			<GridColumn>
				<Field
					name="RRID"
					type="text"
					v-model.trim="formData.RRID"
					placeholder="Enter RRID if available"
					:disabled="readOnly"
				>
					<Icon name="chain" color="currentColor" />

					Research Resource Identifier (RRID)
				</Field>
			</GridColumn>
		</template>

		<GridColumn>
			<Field
				name="comments"
				v-model="formData.comments"
				:disabled="readOnly"
			>
				<Icon name="comment" />

				Additional Comments or Instructions
			</Field>
		</GridColumn>

		<GridColumn v-if="userRoleWeight >= 1000 && formData.reuse">
			<Field
				name="associatedReference"
				v-model="formData.associatedReference"
				:disabled="readOnly"
			>
				<Icon name="document" />

				Associated Reference
			</Field>
		</GridColumn>

		<GridColumn v-if="userRoleWeight >= 1000">
			<Field
				name="headSentence"
				v-model="formData.headSentence"
				:disabled="readOnly"
			>
				<Icon name="document" />

				Associated Head Sentence
			</Field>
		</GridColumn>
	</Grid>
</template>

<script>
/**
 * External Dependencies
 */
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex'

/**
 * Internal Dependencies
 */
import Icon from '@/components/icon/icon';
import Field from '@/components/field/field';
import Grid, { GridColumn } from '@/components/grid/grid';
import Checkboxes from '@/components/checkboxes/checkboxes';
import FieldCheckbox from '@/components/field-checkbox/field-checkbox';
import FieldSelect from '@/components/field-select/field-select';

export default {
	/**
	 * Name
	 */
	name: 'formDatasetsCode',

	/**
	 * Components
	 */
	components: {
		Grid,
		Icon,
		Field,
		Checkboxes,
		GridColumn,
		FieldSelect,
		FieldCheckbox
	},

	/**
	 * Props
	 */
	props: {
		formData: {
			type: Object,
			default: () => {}
		},
		kind: {
			type: String,
			default: () => ''
		},
		readOnly: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			showBestPractices: false,
			showSuitableRepository: false,
		}
	},

	/**
	 * Computed
	 */
	computed: {
		...mapGetters('account', ['userRoleWeight', ]),
		...mapGetters('pdfViewer', ['dataTypes']),
		dataTypesOptions() {		
			return Object.keys(this.dataTypes.dataTypes).map(key => ({
					value: key,
					label: key,
				}));
		},
		subTypeOptions() {
			const subTypes = this.dataTypes.dataTypes[this.formData.dataType];

			return subTypes.map(type => ({
					value: type,
					label: type,
				})).concat([{
					value: '',
					label: 'None',
				}]);
		}
	},

	/**
	 * Validations
	 */
	validations: {
		type: {
			required
		},
		subtype: {
			required
		},
		permalink: {
			required
		}
	},

	/**
	 * Methods
	 */
	methods: {
		textToggle(check) {
			return check ? 'Hide' : 'Show';
		},
		handleDataTypeChange(value) {
			this.formData.dataType = value;
			this.formData.subType = '';
		}
	}
}
</script>

